<template>
  <div class="container">
    <div class="page-header">
      <h1 class="mb-0">Order change requests</h1>
    </div>

    <div
      class="d-flex flex-row-reverse align-items-center justify-content-between mb-3"
    >
      <div class="d-flex gap-8 flex-column">
        <div class="row d-flex align-items-center gap-8">
          <label class="form-label"> Status: </label>
          <div>
            <label
              v-for="(status, key) in statusArray"
              :key="key"
              class="form-check form-check-inline"
            >
              <input
                class="form-check-input"
                type="checkbox"
                :checked="status.checked"
                @change="onStatusChange(status)"
              />
              <span class="form-check-label">{{status.label}}</span>
            </label>
          </div>
        </div>

        <div class="w-fit d-flex align-items-center gap-8 align-self-end">
          <label class="form-label mb-0"> Type: </label>
          <select
            v-model="selectedFilters.type"
            class="form-control custom-select"
            @change="applyFilters"
          >
            <option value="">Show all</option>
            <option
              v-for="item in OrderChangeRequestTypes"
              :key="item.key"
              :value="item.key"
            >
              {{item.value}}
            </option>
          </select>
        </div>
      </div>

      <div>
        <span
          class="text-truncate"
        >Showing {{numeral(items.length).format("0,0")}} of
          {{numeral(meta.total).format("0,0")}} total requests
        </span>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="card">
          <order-change-requests-table
            :items="items"
            :page="page"
            :totalPages="meta.totalPages"
            :loading="listLoading"
            @goToPage="goToPage"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OrderChangeRequestStatus from '@/assets/enums/OrderChangeRequestStatus';
import OrderChangeRequestTypes from '@/assets/enums/OrderChangeRequestTypes';
import OrderChangeRequestsTable from '@/components/OrderChangeRequestsTable.vue';
import list from '@/mixins/list';
import {orderChangeRequest} from '@/services';
import submitting from '@hellochef/shared-js/mixins/submitting';

export default {
  components: {
    OrderChangeRequestsTable,
  },
  mixins: [list, submitting],
  data() {
    return {
      OrderChangeRequestStatus,
      OrderChangeRequestTypes,
      selectedFilters: {
        status: this.$route.query.status ?? ['pending'],
        type: this.$route.query.type ?? '',
      },
    };
  },
  computed: {
    filters() {
      // to remove a empty value property
      let qp = Object.fromEntries(
        Object.entries(this.selectedFilters).filter(([_, v]) => v !== ''),
      );
      // retain if the query exists
      if (this.query) {
        qp = {
          ...qp,
          query: this.query,
        };
      }
      return qp;
    },
    statusArray() {
      // get all the change status
      const statuses = Object.assign({}, OrderChangeRequestStatus);
      // convert to array and assign checked property
      const statusArray = Object.values(statuses).map(it => {
        return {
          ...it,
          checked: this.selectedFilters.status.includes(it.name),
        };
      });
      this.$router.replace({query: this.filters});
      return statusArray;
    },
  },
  methods: {
    fetchData(page) {
      /// get and return data
      return orderChangeRequest.getByParameters({
        limit: this.limit,
        page,
        ...this.filters,
        with: 'createdBy,updatedBy,order',
      });
    },
    transformData(data) {
      data.items.map(it => {
        it.loading = false;
        return it;
      });
      return data;
    },
    onStatusChange(status) {
      status.checked = !status.checked;
      this.selectedFilters.status = this.statusArray
        .filter(it => it.checked)
        ?.map(it => it.name.toLowerCase());
      this.applyFilters();
    },
    applyFilters() {
      this.goToPage({page: 1});
      this.$router.replace({query: this.filters});
      this.refresh();
    },
  },
};
</script>
